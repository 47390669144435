import { reactive } from '/js/petite-vue/dist/petite-vue.es.js';

function reactiveAtom(atom, withActions) {
	let state = null;
	let isObject = typeof atom.value === 'object' && !Array.isArray(atom.value)

	if (!isObject) {
		console.warn('Only objects are supported');
		return;
	}

	state = reactive(atom.value)

	const checkProps = (newobj, oldobj, substate) => {
		for (const prop in newobj) {
			if (newobj.hasOwnProperty(prop)) {
				let np = newobj[prop]
				let op = oldobj && oldobj[prop]

				if (typeof np === 'object' && !Array.isArray(np)) {
					checkProps(np, op, substate ? substate[prop] : state[prop])
				}
				else if (np !== op) {
					if (!substate) state[prop] = np
					else substate[prop] = np;
				}
			}
		}
	}
	atom.subscribe(checkProps);

	return withActions ? [state, atom.actions] : state;
}

export { reactiveAtom };
